import deepmerge from 'deepmerge';
import { config as baseConfig } from './base';
import { logLevels } from '@common/log/logger-types';
import { CatalogsMap } from '@utils/util-types';

//
// new production site
//
// app.jiveworld.com
//
// nps deploy.LIVE
//

export const config = deepmerge(baseConfig, {
  apiEnv: 'LIVE',
  caliServerEnv: 'LIVE',

  //priceIncreaseDate: 'April 30',

  // obsolete, should remove from all configs
  authShortCodeEnabled: false,

  // can probably bump this up once live and stable for a while
  updateCheckIntervalMs: 3 * 60 * 1000, // check for new deployments

  inviteGateEnabled: false,
  // inviteGateEnabled: true, // keep gate up until closer to launch

  piMenuEnabled: false,
  showImportAction: false,

  showUnhandledErrors: false, // turn off until more stable
  showErrorDetails: false,
  showWarningAlerts: false,
  // invariants: {
  //   alert: false, // consider turning off invariant red toasts for production
  // },

  // disabled via spa.vite.config.ts
  // i18n: {
  // },

  enableSoundbitesOrientationVideo: true,

  enableImmersiveView: false,

  stripe: {
    publishableKey: 'pk_live_6j07aeOcmrZn9GxoyyW1GvCP00TdILPo5t',
  },

  bugsnag: {
    // cali-spa - live
    apiKey: '3f38266ac6d4c7f00c14a3a176ab202a',
    enabled: true,
  },

  sentry: {
    enabled: true,
    /// spa-live dsn
    dsn: 'https://6b3a7dce5f8840d3900dcb21228e5b19@o260366.ingest.sentry.io/1523395',
    tracesSampleRate: 0.25, // performance metrics
    enableReplay: false, // keep off until burned in more with staging and beta
    replaysSessionSampleRate: 0, // should probably be kept very small if ever enabled
    replaysOnErrorSampleRate: 0,
  },

  // raygun: {
  //   enabled: false,
  //   apiKey: 'LDPpqxBKelNL39UAj1L9GQ', // spa-LIVE
  // },

  // TODO
  analytics: {
    mixpanel: {
      // mixpanel project: Lupa Prod (teal)
      projectToken: '9785d2e504e2aef251c4b2a16e281027',
    },
    // segmentWriteKey: 'CTk3DASrN1y8cT436iKODnHG9N7sd8JJ', // lupa-spa (lupa-prod)
    // property: jiveworld prod, stream name: my.lupa.app
    googleAnalyticsKey: 'G-01RFZ85QN7',
    metaPixelId: '377747195169609',
    advertising: {
      enableSignupConversionTracking: true,
    },
  },

  firebase: {
    forceDisconnectedSampleRate: 0,
  },

  logging: {
    bufferLogger: {
      enabled: true,
      bufferActivated: false,
      consoleActivated: true,
    },
    minimumLogLevel: logLevels.info,
  },

  crossSiteCookiesSupported: true,

  website: {
    urls: {
      // marketingSite: 'https://www.jiveworld.com',
      marketingSite: 'https://www.jiveworld.com',
      jwPressServer: 'https://resources.jiveworld.com',

      facebookTeachersGroup: 'https://www.facebook.com/groups/jiveworld',
    },
    downloadUrls: {
      playStore:
        'https://play.google.com/store/apps/details?id=com.jiveworld.lupa',
    },
    cookieDomain: '.jiveworld.com',
  },

  mailingList: {
    // except will always exclude `@jiveworld.com` email unless `+ml` also included
    autoOptInEnabled: true,
  },
  soundbites: {
    disabled: false,
    epochDate: '2023-07-10',
    // tentative release date
    // calendarCutoffDate: '2023-11-27', // calendar only visible for users who completed a soundbite prior to this date
    legacyCutoffDate: '2023-12-18', // forces dashboard completed soundbites link visibility for users who have completed soundbites before this date
  },

  lambdaFunctions: {
    enabled: true,
  },

  player: {
    debug: false, // turns off the '~' debug mode by default
    enableOldIosScrollFix: false,
  },

  defaultL1: 'en',
  // forcedL2: 'es',
  catalogs: {
    es: {
      defaultL1: 'en',
      en: 'lupa',
    },
    en: {
      defaultL1: 'pt',
      pt: 'english-pt',
      es: 'english-es',
    },
  } as CatalogsMap,

  showEnEsCatalogChoice: false,

  // enableDarkModeSupport: false,
});
